import React from 'react';
import Header from '../components/Header';
import '../css/App.css';
import PostsPage from '../components/PostsPage';
function Feed() {
    return (
        <div className='App'>
            <Header page={"Feed"} />
            <PostsPage coll={"feed"} />
        </div>
    );
}

export default Feed;
