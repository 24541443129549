import { toast } from 'react-hot-toast';
import '../css/App.css'

export const success = (message, duration) => {
  toast.success(message, {
    position: "bottom-center",
    style: {
      border: '1px solid var(--highlight)',
      padding: '16px',
      background: 'hsl(var(--primary))',
      color: 'hsl(var(--font-color))',
    },
    duration: duration
  });
}

export const error = (message, duration) => {
  toast.error(message, {
    position: "bottom-center",
    style: {
      border: '1px solid var(--highlight)',
      padding: '16px',
      background: 'hsl(var(--primary))',
      color: 'hsl(var(--font-color))',
    },
    duration: duration
  });
}


export const notification = (message) => {
  toast.custom(t => (
    <div className='customToast'>
      <span>
        {message}
        <button onClick={() => toast.dismiss(t.id)}>
          X
        </button>
      </span>
    </div>

  ));
}

