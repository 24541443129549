import { useState, useEffect } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext'; // Import the AuthContext

const useAuth = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [userName, setUserName] = useState('');


    const checkUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`, { withCredentials: true });

            if (response.data.result === true) {
                setUserEmail(response.data.email);
                setUserName(response.data.username);
                setLoggedIn(true);
            }
        } catch (error) {
            console.error('Error checking user:', error);
        }
    };

    const logout = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_URL}/logout`, { withCredentials: true });

            if (response.data.result === false) {
                console.error("Couldn't log out.");
            } else {
                setUserEmail('');
                setUserName('');
                setLoggedIn(false);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    useEffect(() => {
        checkUser();
    }, []);

    return { loggedIn, userEmail, userName, login: setUserEmail, logout, checkUser };
};

export const AuthProvider = ({ children }) => {
    const auth = useAuth();

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
};

export default useAuth;
