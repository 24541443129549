import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { success, error } from '../components/CustomToast'
import {Toaster} from 'react-hot-toast';


import { personCircleSharp, reloadOutline, timeOutline, openOutline, shareOutline, trashOutline, ellipsisHorizontalOutline, thumbsUp, thumbsUpOutline, thumbsDownOutline, thumbsDown, cellularOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';


function Post({ details, userName, coll }) {
  const { title, author, timestamp, content, id, likes, dislikes, image, views, userReaction } = details;

  const [likeState, setLikeState] = useState(false);
  const [likeCount, setLikeCount] = useState(likes);
  const [btnState, setBtnState] = useState(false);

  const [dislikeState, setdislikeState] = useState(false);
  const [dislikeCount, setDislikeCount] = useState(dislikes);

  const [showShareDiv, setShowShareDiv] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState(null); // State to store the clicked image URL

  const deletePost = async () => {
    try {
      if (userName) {
        let response;
        response = await axios.delete(`${process.env.REACT_APP_API_URL}/deletePost`,
          {
            withCredentials: true,
            data: {
              id: id,
              username: userName,
              coll: coll
            }
          });
        if (response.status === 200) {
          success(response.data.message, 5000);
          window.location.reload()
        } else {
          error(response.data.message, 3000);
        }
      } else {
        error("Please login.", 3000);
      }
    } catch (errorMessage) {
      if (errorMessage.response && errorMessage.response.status >= 400 && errorMessage.response.status < 500) {
        error(errorMessage.response.data.message, 3000);
      } else {
        error("An error occurred. Please try again later.", 3000);
      }
    }
  };


  const sharePost = () => {
    setShowShareDiv((prevState) => !prevState);
  };

  const handleShareDivClick = (e) => {
    e.stopPropagation();
  };

  const handleLike = async () => {
    if (!userName) {
      error('Please login.', 5000);
      return;
    }
    try {
      setBtnState(true);
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/react?id=${id}&t=like&c=${coll}`, { withCredentials: true });
      if (response.data.result === true) {
        setDislikeCount(response.data.dislikes)
        setLikeCount(response.data.likes)
        setLikeState(prevState => !prevState);
        setdislikeState(false);
      } else {
        console.log("Request error: ", response)
      }
    } catch (error) {
      console.error('Error: ', error);
    }
    setBtnState(false);
  };

  const handleDislike = async () => {
    if (!userName) {
      error('Please login.', 5000);
      return;
    }
    try {
      setBtnState(true);
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/react?id=${id}&t=dislike&c=${coll}`, { withCredentials: true });
      if (response.data.result === true) {
        setDislikeCount(response.data.dislikes)
        setLikeCount(response.data.likes)
        setdislikeState(prevState => !prevState);
        setLikeState(false);
      } else {
        console.log("Request error: ", response)
      }
    } catch (error) {
      console.error('Error: ', error);
    }
    setBtnState(false);

  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`https://campustalk.io/post?type=${coll}&id=${id}`)
      .then(() => {
        success('Copied to clipboard.', 5000);
      })
      .catch((error) => {
        console.error('Error copying text to clipboard:', error);
      });
  };

  function openPostInNewTab() {
    window.open(`/post?type=${coll}&id=${id}`, '_blank');

  }

  function timeAgo(timestamp) {
    const currentTime = new Date().getTime();
    const providedUTCTime = new Date(timestamp).getTime();

    const difference = currentTime - providedUTCTime;
    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return days === 1 ? '1d' : `${days}d`;
    } else if (hours > 0) {
      return hours === 1 ? '1h' : `${hours}h`;
    } else if (minutes > 0) {
      return minutes === 1 ? '1m' : `${minutes}m`;
    } else if (seconds > 0) {
      return seconds === 1 ? '1s' : `${seconds}s`;
    } else {
      return 'Just now';
    }
  }

  function formatViews(views) {
    if (views >= 1000) {
      const roundedViews = Math.round(Math.ceil(views) / 100) / 10;
      return `${roundedViews}K`;
    } else {
      return views.toString();
    }
  }

  const parseContent = (content) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const italicRegex = /\*(.*?)\*/g;
    const underlineRegex = /__(.*?)__/g;
    const hyperlinkRegex = /\[(.*?)\]\((.*?)\)/g; // Regex to match [text](link)
  
    // Split content by newline characters
    const lines = content.split('\n');
  
    // Process each line separately
    const parsedLines = lines.map((line, index) => {
      // Replace bold markup with <strong> tag
      let parsedLine = line.replace(boldRegex, '<strong>$1</strong>');
  
      // Replace italic markup with <em> tag
      parsedLine = parsedLine.replace(italicRegex, '<em>$1</em>');
  
      // Replace underline markup with <u> tag
      parsedLine = parsedLine.replace(underlineRegex, '<u>$1</u>');
  
      // Replace hyperlink markup with <a> tag
      parsedLine = parsedLine.replace(hyperlinkRegex, '<a href="$2">$1</a>');
  
      // Wrap the line in a <p> tag
      return <p key={index} dangerouslySetInnerHTML={{ __html: parsedLine }} />;
    });
  
    return parsedLines;
  };
  

  const handleImageClick = (imageUrl) => {
    setEnlargedImage(imageUrl); // Update state with clicked image URL
  };

  const closeEnlargedImage = () => {
    setEnlargedImage(null)
  }

  const randomNumber = Math.floor(Math.random() * 5) + 1;
  const pfpImageUrl = `https://ui.shadcn.com/avatars/0${randomNumber}.png`;

  useEffect(() => {
    if (userName) {
      if (userReaction == true) {
        setLikeState(true);
      } else if (userReaction == false) {
        setdislikeState(true);
      }
    }
  }, [userName, likes, dislikes]);

  return (
    <div className='post'>
      <script type="module" src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"></script>
      <script nomodule src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"></script>
      <div className='header'>
        <div className='title'>
          {title ? (
            title
          ) : (
            <span className='author-title'>
              <IonIcon icon={personCircleSharp} />
              {/* <img src={pfpImageUrl} /> */}

              <p>{author}</p>
            </span>
          )}
        </div>
      </div>




      <div className='post-details'>
        {parseContent(content)}
      </div>

      {image && (
        <div className='post-image'>
          <img src={image} onClick={() => handleImageClick(image)} />
        </div>
      )}
      {enlargedImage && (
        <div className='enlarged-image-container' onClick={closeEnlargedImage}>
          <img src={enlargedImage} className='enlarged-image' />
        </div>
      )}

      <div className='post-footer'>
        <div className='post-footer-emojis'>
          <button disabled={btnState} className={likeState === false ? 'emoji' : 'emoji emoji-active'} onClick={handleLike}>
            {likeCount}
            <a className='emoji-icon'>
              {!btnState ? (
                likeState ? <IonIcon icon={thumbsUp} /> : <IonIcon icon={thumbsUpOutline} />
              ) : (
                <IonIcon className='spin' icon={reloadOutline} />
              )}
            </a>
          </button>
          <button disabled={btnState} className={dislikeState === false ? 'emoji' : 'emoji emoji-active'} onClick={handleDislike}>
            {dislikeCount}
            <a className='emoji-icon'>
              {!btnState ? (
                dislikeState ? <IonIcon icon={thumbsDown} /> : <IonIcon icon={thumbsDownOutline} />
              ) : (
                <IonIcon className='spin' icon={reloadOutline} />
              )}
            </a>
          </button>
          <button className="emoji views">
            {formatViews(views)}
            <a className='emoji-icon'>
              <IonIcon icon={cellularOutline} />
            </a>
          </button>
          <button className='emoji views'>
            {timeAgo(timestamp)}
            <a className='emoji-icon'>
              <IonIcon icon={timeOutline} />
            </a>
          </button>


        </div>
        <div className='post-footer-emojis'>
          {showShareDiv && (
            <div className='share-div-open'>
              <div className='share-div emoji open-hover' onClick={openPostInNewTab}>
                <IonIcon icon={openOutline} />
              </div>
              <div className='share-div emoji share-hover' onClick={copyToClipboard}>
                <IonIcon icon={shareOutline} />
              </div>
              {author && userName === author && (
                <div className='share-div emoji delete-hover' onClick={deletePost}>
                  <IonIcon icon={trashOutline} />
                </div>
              )}

            </div>
          )}
          <div className={showShareDiv === false ? 'emoji' : 'emoji emoji-active'} onClick={sharePost}><IonIcon icon={ellipsisHorizontalOutline} /></div>

        </div>
      </div>
      <Toaster  reverseOrder={true} />

    </div>
  );
}

export default Post;
