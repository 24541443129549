import Header from '../components/Header';
import '../css/App.css';
import { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import step1 from '../assets/iosS1.png';
import step2 from '../assets/iosS2.png';
import step3 from '../assets/iosS3.png';
import { flameOutline, close, starOutline, rocketOutline, bookOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { notification } from '../components/CustomToast';

function Home() {

  const [homeScreenNoti, setHomeScreenNoti] = useState(false);
  const [isDeviceMobile, setIsDeviceMobile] = useState(false);




  function getDevice() {
    const screenWidth = window.screen.width;
    if (window.screen.width <= 450) {
      setIsDeviceMobile(true)
    }
  }

  const [activeIndex, setActiveIndex] = useState(0);
  const totalSteps = 3; // Total number of steps
  const handleHomeScreenNoti = () => {
    setActiveIndex(0)
    setHomeScreenNoti(prevState => !prevState);
  };
  const handleNextStep = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % totalSteps);
  };

  useEffect(() => {
    getDevice(0);
    // notification("HEY")
  }, []);


  return (
    <div className="App App-home">

      <Header page={"Home"} />
      <div className='Home'>
        <div className='intro'>
          <div className='title-bx'>
            <h1 className='title'>
              <span>Uncover</span><span>Campus</span><span>Talk</span>
            </h1>
            <p className='description'>
              Discover campus secrets, browse through university gossip and spread your own information.
            </p>
            <br />

          </div>
        </div>
        <div className='intro-2'>
          <Link to='/profile'>
            <button className='button-1'>
              <IonIcon icon={starOutline} /> 
              <p>•</p> 
              <p>Get started</p>
            </button>
          </Link>
          <Link to='/news'>
            <button className='button-1'>
              <IonIcon icon={rocketOutline} />
              <p>•</p>
              <p>View news</p> 
            </button>
          </Link>
          <Link to='/info'>
            <button className='button-1'>
              <IonIcon icon={bookOutline} />
              <p>•</p>
              <p>Read our rules</p>
            </button>
          </Link>

        </div>
        {isDeviceMobile && (
          <div className='intro-2'>
            <button className='button-1' onClick={handleHomeScreenNoti}>
            <IonIcon icon={flameOutline} />
              <p>•</p>
              <p>Add to Home Screen</p>
            </button>
          </div>
        )}

        {homeScreenNoti && (
          <div className='ios-home-wrapper' onClick={handleNextStep}>
            <div className='ios-home'>
              <div className="step-div" style={{ display: activeIndex === 0 ? 'flex' : 'none' }}>
                <div className='close-btn-ios' onClick={handleHomeScreenNoti}>
                  <h1>Step 1 of 3</h1>
                  <IonIcon icon={close} />
                </div>
                <h1>Click the Share icon on your toolbar.</h1>
                <img src={step1} />
              </div>
              <div className="step-div" style={{ display: activeIndex === 1 ? 'flex' : 'none' }}>
                <div className='close-btn-ios' onClick={handleHomeScreenNoti}>
                  <h1>Step 2 of 3</h1>
                  <IonIcon icon={close} />
                </div>
                <h1>Click the 'Add to Home Screen' button.</h1>
                <img src={step2} />
              </div>
              <div className="step-div" style={{ display: activeIndex === 2 ? 'flex' : 'none' }}>
                <div className='close-btn-ios' onClick={handleHomeScreenNoti}>
                  <h1>Step 3 of 3</h1>
                  <IonIcon icon={close} />
                </div>
                <h1>Click the 'Add' button to add to home screen.</h1>
                <img src={step3} />
              </div>
            </div>
          </div>
        )}
        <div><Toaster reverseOrder={true} /></div>
      </div>
    </div>


  );
}

export default Home;
