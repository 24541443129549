import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './css/App.css';
import Home from './pages/Home';
import Gossip from './components/Gossip';
import Info from './pages/Info';
import News from './pages/News';
import Feed from './pages/Feed';
import Profile from './pages/Profile';
import Admin from './pages/Admin';
import { AuthProvider } from './components/useAuth';

function App() {
  return (
    <AuthProvider> 
      <Router>
        <Routes>
          <Route path="/home" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="/post" element={<Gossip />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/info" element={<Info />} />
          <Route path="/news" element={<News />} />
          <Route path="/feed" element={<Feed />} />
          <Route path="/admin/post" element={<Admin />} />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
