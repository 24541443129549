import React, { useState } from 'react';
import axios from 'axios';
import '../css/Admin.css';
import '../css/index.css'
import { Toaster } from 'react-hot-toast';
import { success, error } from '../components/CustomToast'
import Header from '../components/Header'


function isFormEmpty(formData) {
    console.log(formData)
    return Object.values(formData).some(value => value.trim() === '');
}

function isPasswordEmpty() {
    const password = document.getElementById("password").value;
    return password.trim() === '';
}

function Share() {
    const [postData, setPostData] = useState({
        title: '',
        content: '',
        image: ''
    });

    const [reactionData, setReactionData] = useState({
        id: '',
        coll: 'news',
        type: 'like',
        size: ''
    });
    const handlePostChange = (e) => {
        setPostData({ ...postData, [e.target.name]: e.target.value });
    };

    const handleReactionChange = (e) => {
        setReactionData({ ...reactionData, [e.target.name]: e.target.value });
    };

    const handleSubmitPost = async (e) => {
        e.preventDefault();
        try {
            if (isFormEmpty(postData)) {
                return error("Please enter data into all fields.", 3000);
            }
            if (isPasswordEmpty()) {
                return error("Please enter the password.", 3000);
            }
            const password = document.getElementById("password").value;
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/addPost?p=${password}`, postData);
            setPostData({
                title: '',
                content: '',
                image: ''
            });
            success(response.data, 5000)
        } catch (error) {
            console.error('Error creating post:', error);
        }
    };

    const handleSubmitReactions = async (e) => {
        e.preventDefault();
        try {
            if (isFormEmpty(reactionData)) {
                return error("Please enter data into all fields.", 3000);
            }
            if (isPasswordEmpty()) {
                return error("Please enter the password.", 3000);
            }
            const password = document.getElementById("password").value;
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/addReaction?p=${password}`, reactionData);
            setReactionData({
                id: '',
                coll: 'news',
                type: 'like',
                size: ''
            });
            success(response.data, 5000);
        } catch (error) {
            console.error('Error adding reactions:', error);
        }
    };


    return (
        <div>
            <Header page={"Admin"} />
            <div className='Admin'>
                <div className='admin-form'>
                    <h1>Admin Section</h1>
                    <label className='admin-label'>
                        Password:
                    </label>
                    <input id="password" name="password" />
                </div>


                <form className='admin-form' onSubmit={handleSubmitPost}>
                    <h1>Create Post</h1>


                    <label className='admin-label'>
                        Title:
                    </label>
                    <input type="text" name="title" value={postData.title} onChange={handlePostChange} />
                    <label className='admin-label'>
                        Image:
                    </label>
                    <input type="text" name="image" value={postData.image} onChange={handlePostChange} />

                    <label className='admin-label'>
                        Content:
                    </label>
                    <textarea name="content" value={postData.content} onChange={handlePostChange} />
                    <button className="admin-btn" type="submit">Submit</button>
                </form>

                <form className='admin-form' onSubmit={handleSubmitReactions}>
                    <h1> Add reactions </h1>

                    <label className='admin-label'>
                        Post ID:
                    </label>
                    <input type="text" name="id" value={reactionData.id} onChange={handleReactionChange} />
                    
                    <label className='admin-label'>
                        Size:
                    </label>
                    <input type="number" name="size" value={reactionData.size} onChange={handleReactionChange} />
                    
                    <label className='admin-label'>
                        Collection:
                    </label>
                    <select className="share-btn share-select" name="coll" value={reactionData.coll} onChange={handleReactionChange}>
                        <option value="news">News</option>
                        <option value="feed">Feed</option>
                    </select>

                    <label className='admin-label'>
                        Reaction:
                    </label>
                    <select className="share-btn share-select" name="type" value={reactionData.type} onChange={handleReactionChange}>
                        <option value="like">Like</option>
                        <option value="dislike">Dislike</option>
                    </select>


                    <button className="admin-btn" type="submit">Submit</button>
                </form>
                <Toaster reverseOrder={true} />

            </div>
        </div>
    );
}

export default Share;
