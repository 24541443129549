import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import '../css/Profile.css';
import Login from '../components/Login';
import { Toaster } from 'react-hot-toast';
import useAuth from '../components/useAuth';
import { logOutOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import PostsPage from '../components/PostsPage';


function Profile() {

    const { loggedIn, userEmail, userName, login, logout, checkUser, userType } = useAuth(); // Use the custom hook
    const [posts, setPosts] = useState([]);
    const [start, setStart] = useState(0);
    const [loadMore, setLoadMore] = useState(true);

    const [showMenu, setShowMenu] = useState(false);


    const handleShowMenu = () => {
        if (showMenu) {
            setShowMenu(false);
        } else {
            setShowMenu(true);
        }

    };


    function processEmail(email) {
        if (email.includes("@")) {
            return maskEmail(email)
        } else {
            return "Twitter User"
        }
    }

    function maskEmail(email) {
        let parts = email.split("@");
        return `${maskString(parts[0])}@${maskString(parts[1])}`
    }

    function maskString(str) {
        let start = str[0];
        let last = str[str.length - 1]
        return `${start}***${last}`
    }


    useEffect(() => {
    }, []);

    return (
        <div className="App Profile-home">
            <Header page={"Profile"} />
            <div className='Profile' >
                {loggedIn ? (
                    <div className='user-profile-wrapper'>
                        <div className='user-profile'>
                            <div className='profile-titles user-details'>
                                <h1> Profile </h1>
                                {/* <img className='profile-picture' src={`https://static.vecteezy.com/system/resources/thumbnails/020/911/732/small/profile-icon-avatar-icon-user-icon-person-icon-free-png.png`} /> */}
                                <div className='input-bx'>
                                    <label className='input-label'>Username</label>
                                    <text>@{userName}</text>
                                </div>
                                <div className='input-bx'>
                                    <label className='input-label'>Email</label>
                                    <text>{processEmail(userEmail)}</text>
                                </div>
                            </div>
                            <div className='profile-actions'>
                                {/* <a className='logout-button' onClick={handleShowMenu}><IonIcon icon={appsOutline} /></a> */}
                                {/* {showMenu && ( */}
                                <a className='menu-button logout-hover' onClick={logout}><IonIcon icon={logOutOutline} /></a>
                                {/* // )} */}

                            </div>
                            {/* <div className='profile-bottom'>
                            </div> */}


                            {/* <div className='profile-bottom'>
                                {viewMyPosts ? (
                                    <>
                                        <h1>My Posts</h1>

                                        {loggedIn && (
                                            <div className='posts-container profile-posts-main'>
                                                <div className='posts profile-posts'>
                                                    {posts.map((post, index) => (
                                                        <Post key={index} userName={userName} title={post.author} views={post.views} date={post.date} content={post.content} id={post.id} likes={post.likes} dislikes={post.dislikes} image={post.image} coll={"feed"} />
                                                    ))}
                                                    {loadMore && (
                                                        <div className='load-container'>
                                                            <button className='share-btn-feed' onClick={fetchPosts}>
                                                                Load More
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <a className='viewposts' onClick={handleViewPosts}>
                                        <text>View My Posts</text> <IonIcon icon={arrowDownOutline} />
                                    </a>
                                )}
                            </div> */}


                        </div>
                    </div>
                ) : (
                    <Login onLogin={login} />
                )}

                <div>
                    <Toaster position='bottom-left' reverseOrder={true} />
                </div>
            </div>
        </div>
    );
}

export default Profile;
