import React, { useState, useEffect } from 'react';
import '../css/Profile.css'
import axios from 'axios';

import { Toaster } from 'react-hot-toast';
import { success, error } from '../components/CustomToast'
import { logoTwitter, logoGoogle } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';


function Login({ onLogin }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [cemail, setCEmail] = useState('');
    const [cpassword, setCPassword] = useState('');
    const [ccpassword, setCCPassword] = useState('');
    const [ccode, setccode] = useState('');


    const [createStep2, setCreateStep2] = useState(false);


    const [showCreateAccount, setShowCreateAccount] = useState(false);


    const toggleCreateAccount = () => {
        setShowCreateAccount(!showCreateAccount);
    };


    const login = async () => {
        try {
            if (!email.includes('@') || !email.includes('.')) {
                error("Invalid email.", 5000)
            } else if (password.length < 4) {
                error("Invalid password.", 5000)
            } else {

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/login`,
                    { email, password },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                    }
                );
                if (response.data.message === "Login successful") {
                    onLogin(email);
                    success("Login success.", 5000)
                    window.location.reload();
                } else {
                    error("Login failed.", 5000)
                }
            }
        } catch (error) {
            error("Incorrect details.", 5000)

        }
    };

    const createAccount = async () => {

        try {
            if (!cemail.includes('@') || !cemail.includes('.')) {
                error("Invalid email.", 5000)
            } else if (cpassword.length < 4) {
                error("Invalid password.", 5000)
            } else if (cpassword != ccpassword) {
                error("Passwords don't match.", 5000)
            } else {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/createAccount`,
                    { cemail },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                    }
                );
                if (response.data.success === true) {
                    success("Code sent.", 5000)
                    setCreateStep2(true)
                }
                else if (response.data.success === false) {
                    error(response.data.message, 5000)
                }
                else {
                    error("Code not sent.", 5000)
                }
            }
        } catch {
            error("Something went wrong.", 5000)

        }
    }

    const confirmCode = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/confirmCode`,
                { cemail, cpassword, ccode },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                }
            );
            if (response.data.success === true) {
                setShowCreateAccount(false)
                success("Account created. You can now login.", 10000)
            } else {
                error("Code incorrect.", 5000)
            }
            setCreateStep2(true)
        } catch {
            error("Something went wrong.", 5000)

        }
    }

    const twitterOAuth = async () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/auth/twitter`
    }

    const googleOAuth = async () => {
        window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`
    }



    return (
        <div className='Login-wrapper'>
            <div className='Login' style={{ display: showCreateAccount ? 'none' : 'block' }}>
                <h1>Profile</h1>
                <div className='user-input-bxs'>
                    <div className='input-bx'>
                        <label className='input-label'>Email</label>
                        <input
                            className='login-input username'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className='input-bx'>
                        <label className='input-label'>Password</label>
                        <input
                            className='login-input password'
                            type='password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>
                <button className='login-button' id="enter" onClick={login}>Log in</button>
                <div className='new-register'>
                    <text> Not a member yet? <a onClick={toggleCreateAccount}>Sign up</a></text>
                </div>
                <div className='hr-wtext'>
                    <div />
                    <span>OR</span>
                    <div />
                </div>
                <div className='alt-login-flow'>


                    <button className='alt-login-btn' onClick={twitterOAuth}>
                        <IonIcon icon={logoTwitter} />
                        <span>Twitter</span>
                    </button>
                    <button className='alt-login-btn coming-soon-hover'>
                    {/* // onClick={googleOAuth} */}
                        <IonIcon icon={logoGoogle} />
                        <span>Google</span>
                    </button>
                </div>
                {/* <div className='login-hr' /> */}

            </div>

            {createStep2 ? (
                <div className='Login' style={{ display: showCreateAccount ? 'block' : 'none' }}>
                    <h1>Confirm</h1>
                    <div className='user-input-bxs'>
                        <div className='input-bx'>
                            <label className='input-label'>Enter the code sent to your email.</label>
                            <input
                                className='login-input username'
                                value={ccode}
                                onChange={(e) => setccode(e.target.value)}
                            />
                        </div>
                    </div>
                    <button className='login-button' id="enter-code" onClick={confirmCode}><p className='login-enter'>Create account</p></button>

                </div>
            ) : (
                <div className='Login' style={{ display: showCreateAccount ? 'block' : 'none' }}>

                    <h1>Sign up</h1>
                    <div className='user-input-bxs'>
                        <div className='input-bx'>
                            <label className='input-label'>Email</label>
                            <input
                                className='login-input username'
                                value={cemail}
                                onChange={(e) => setCEmail(e.target.value)}
                            />
                        </div>
                        <div className='input-bx'>
                            <label className='input-label'>Password</label>
                            <input
                                className='login-input password'
                                value={cpassword}
                                type='password'
                                onChange={(e) => setCPassword(e.target.value)}
                            />
                        </div>
                        <div className='input-bx'>
                            <label className='input-label'>Confirm Password</label>
                            <input
                                className='login-input password'
                                value={ccpassword}
                                type='password'
                                onChange={(e) => setCCPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <button className='login-button' id="create-account" onClick={createAccount}><p className='login-enter'>Create account</p></button>
                    <div className='login-hr' />
                    <div className='new-register'>
                        <text> Already a member? <a onClick={toggleCreateAccount}>Log in</a></text>
                    </div>
                </div>
            )}

            {/* </div> */}
            <Toaster position='bottom-left' reverseOrder={true} />
        </div>

    );
}

export default Login;
