import React, { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import axios from 'axios';
import Header from './Header';
import Post from './Post';
import '../css/App.css';
import useAuth from './useAuth';


function Gossip() {
    const [posts, setPosts] = useState([]);
    const [isInDB, setisInDB] = useState(false);

    const { userName } = useAuth();


    const fetchPost = async (id, coll) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/post?id=${id}&c=${coll}`, {withCredentials: true});
            const newPosts = await response.data;
            if (coll === "feed") {
                newPosts.forEach(post => {
                    post.title = post.author;
                });
            }
            newPosts.forEach(post => {
                post.coll = coll;
            });
            if (newPosts.length === 0) {
                setisInDB(true);
            } else {
                setPosts([...newPosts]);
            }
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        const coll = urlParams.get('type');

        fetchPost(id, coll);
    }, []);

    return (
        <div className='App'>
            <Header />
            <div className='main'>
                <div className='posts-container'>
                    {isInDB ? (
                        <div className='posts-header'>
                            <h1 className='p-title'>Not a valid post.</h1>
                        </div>
                    ) : (
                        <div>
                            <div className='posts-header'>
                                <h1 className='p-title'>Post </h1>
                            </div>
                            <div className='posts gossip'>
                                {posts.map((post, index) => (
                                    <Post key={index} details={post} userName={userName} coll={post.coll} />
                                ))}
                            </div>
                        </div>
                    )}
                    <Toaster position='bottom-left' reverseOrder={true} />

                </div>
            </div>
        </div>
    );
}

export default Gossip;
