import React, { useState, useEffect } from 'react';
import Post from '../components/Post';
import '../css/App.css';
import { Toaster } from 'react-hot-toast';
import useAuth from '../components/useAuth';
import Share from '../components/Share';
import axios from 'axios';
import { createOutline ,refreshOutline, arrowDownOutline, reloadOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

function PostsPage({ coll }) {
    const [posts, setPosts] = useState([]);
    const [start, setStart] = useState(0);
    const [loadMore, setLoadMore] = useState(true);
    const [loadedPosts, setLoadedPost] = useState(false)

    const { userName } = useAuth();
    const [showShare, setShowShare] = useState(false);

    const handleShowShare = () => {
        setShowShare(true);
    };

    const handleCloseShare = () => {
        setShowShare(false);
    };

    const fetchPosts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/posts?s=${start}&c=${coll}`, { withCredentials: true });
            const newPosts = await response.data;
            setLoadedPost(true)
            if (newPosts.length < 9) {
                setLoadMore(false);
            }
            const updatedPosts = newPosts.map(post => ({
                ...post
            }));
            setPosts([...posts, ...updatedPosts]);
            const ids = newPosts.map(post => post.id);
            setStart(start + 10);
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    const refreshPosts = async () => {
        // setStart(0);
        // setPosts([]);
        // fetchPosts();
        // console.log(start, posts.length)
        window.location.reload();
    };

    useEffect(() => {
        fetchPosts();
        // console.log(start, posts.length);

        // return () => {
        //     setStart(0);
        //     setPosts([]);
        // };
    }, []);
    

    return (
        <div className='main'>
            <div className='posts-container'>
                <div className='posts-header'>
                <h1 className='p-title'>Latest {coll.charAt(0).toUpperCase() + coll.slice(1)}</h1>
                    <div className='share-btn-feed-wrapper'>


                        <button className='share-btn-feed' onClick={refreshPosts}><IonIcon icon={reloadOutline} />Reload</button>
                        {coll === "feed" && (
                           <button className='share-btn-feed' onClick={handleShowShare}><IonIcon icon={createOutline} />Post</button> 
                        )}
                        
                    </div>

                </div>
                {loadedPosts ? (
                    <div className='posts'>
                    {posts.map((post, index) => (
                        <Post key={index} details={post} userName={userName} coll={coll} />
                    ))}
                    {loadMore && (
                        <div className='load-container'>
                            <button className='share-btn-feed' onClick={fetchPosts}>
                                <p className='move-down'><IonIcon icon={arrowDownOutline} /></p>
                            </button>
                        </div>
                    )}
                </div>
                ) : (
                    <div className='loading-posts'>
                        <IonIcon className='spin' icon={refreshOutline} />
                    </div>
                )}
                

            </div>
            {showShare && (
                <div>
                    <Share handleCloseShare={handleCloseShare} username={userName} />
                </div>
            )}
            <div><Toaster position='bottom-left' reverseOrder={true} /></div>
        </div>

    );
}

export default PostsPage;
