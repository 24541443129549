import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/index.css';
import '../css/Header.css';

import useAuth from './useAuth';
import Theme from './Theme';

import { logoTwitter, sunnyOutline, moonOutline, menuOutline, closeOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

function Header({ page }) {
  const { loggedIn } = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const { toggleTheme, setTheme, themeIcon, iconColor, themeLogo } = Theme();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };


  useEffect(() => {
    let theme = localStorage.getItem("theme");
    setTheme(theme);
  }, []);


  return (
    <div className="Header">
      <div className="header-left">
        <Link className='logo' to="/">
          <img className='logo-theme' src={themeLogo} /> 
          <span>CampusTalk</span>
        </Link>
        <Link className={`nav-btn ${page === "Home" ? "nav-active" : ""}`} to="/">
          Home
        </Link>
        <Link className={`nav-btn ${page === "News" ? "nav-active" : ""}`} to="/news">
          News
        </Link>
        <Link className={`nav-btn ${page === "Feed" ? "nav-active" : ""}`} to="/feed">
          Feed
        </Link>
        <Link className={`nav-btn ${page === "Info" ? "nav-active" : ""}`} to="/info">
          Info
        </Link>
      </div>

      <div className='nav'>
        <div className='burger-menu' onClick={toggleMenu}>
          <IonIcon className="nav-ionicon" icon={menuOutline} />
        </div>

        <div className={`burger-menu-open ${showMenu ? 'bgm-open' : 'bgm-close'}`}>
          <div className='bgm-cont'>
            <div className='bgm-cont-nav'>
              <Link className={` ${page === "Home" ? "nav-active" : ""}`} to="/">
                Home
              </Link>
              <Link className={` ${page === "News" ? "nav-active" : ""}`} to="/news">
                News
              </Link>
              <Link className={` ${page === "Feed" ? "nav-active" : ""}`} to="/feed">
                Feed
              </Link>
              <Link className={` ${page === "Info" ? "nav-active" : ""}`} to="/info">
                Info
              </Link>

              {loggedIn ? (
                <Link className={` ${page === "Profile" ? "nav-active" : ""}`} to="/profile">
                  Profile
                </Link>
              ) : (
                <Link className={` ${page === "Profile" ? "nav-active" : ""}`} to="/profile">
                  Login
                </Link>
              )}
            </div>
            <div className='bgm-btns' >
              <img onClick={toggleTheme} src={themeIcon} />
              <a href="https://x.com/campustalkio" target='_blank'>
                <IonIcon className="nav-ionicon ionicon-twitter" icon={logoTwitter} />
              </a>
              <a onClick={toggleMenu}>
                <img src={`https://img.icons8.com/ios-filled/30/${iconColor}/delete-sign--v1.png`} />

              </a>

            </div>
          </div>
        </div>
        <a className='nav-btn nav-link' href="https://x.com/campustalkio" target='_blank'>
          <IonIcon className="nav-ionicon ionicon-twitter" icon={logoTwitter} />
        </a>
        <a className='nav-btn nav-link' onClick={toggleTheme} >
          <img src={themeIcon} />
        </a>
        {loggedIn ? (
          <Link className={`nav-btn profile ${page === "Profile" ? "nav-active" : ""}`} to="/profile">
            <img src={`https://img.icons8.com/pastel-glyph/64/${iconColor}/person-male--v1.png`} />
            <div>
              Profile
            </div>
          </Link>
        ) : (
          <Link className={`nav-btn profile ${page === "Profile" ? "nav-active" : ""}`} to="/profile">
            <img src={`https://img.icons8.com/ios-filled/50/${iconColor}/login-rounded-right.png`} />
            <div>
              Login
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}

export default Header;
