import React from 'react';
import Header from '../components/Header';
import '../css/App.css';
import '../css/Info.css';

function FAQ() {

    return (
        <div className='App'>

        <Header page={"Info"}/>
        <div className='faq'>
            <h1 className="faq-title">Information</h1>
            <div className='question'>
                <h1>
                    What gossip should I send?
                </h1>
                <p>
                    Send your gossip to <strong>share@campustalk.io</strong>. <br /> <br />
                    Feel free to share your insights with us via email. We appreciate any supporting evidence, including images. Please be aware that explicit photos are strictly prohibited. Any such content will be reported, and the sender's email will be blacklisted, preventing further communication. Once received, we carefully review the provided information and share relevant details.
                </p>
            </div>
            <div className='question'>
                <h1>
                    What do you send out?
                </h1>
                <p>
                    We adhere to a set of principles: 
                </p>
                    <ul>
                        <li><strong>Privacy:</strong> We prioritize privacy, ensuring that no personal details are disclosed, only a first name is used.</li>
                        <li><strong>Defamation.</strong> We strictly refrain from slandering or defaming any individual. Our goal is to share information responsibly.</li>
                        <li><strong>IP.</strong> We respect intellectual property rights and refrain from using copyrighted material.</li>
                        <li><strong>Discrimination.</strong> The information shared, as well as its scope, is unbiased and non-discriminatory.</li>
                        <li><strong>Child Protection.</strong> We are committed to not sharing any information about individuals under the age of 18.</li>
                    </ul>
               
            </div>
            <div className='question'>
                <h1>
                    There is gossip about me, and I don't like it.
                </h1>
                <p>
                    If you've come across information that concerns you, please reach out to us via email, detailing the issue privately. We are here to address your concerns directly, and if necessary, we can provide further assistance. Your comfort and well-being are our priorities.
                </p>
            </div>
        </div>
        </div>
    );
}

export default FAQ;
