import React from 'react';
import Header from '../components/Header';
import '../css/App.css';

import PostsPage from '../components/PostsPage';


function News() {
  

  return (
    <div className='App'>
      <Header page={"News"} />
      <PostsPage coll={"news"} />
    </div>
  );
}

export default News;
