import React, { useState } from 'react';

import axios from 'axios';
import '../css/Share.css';
import {Toaster} from 'react-hot-toast';
import {success, error} from '../components/CustomToast'


function Share({ handleCloseShare, username }) {

  const [formData, setFormData] = useState({
    author: username,
    content: '',
    type: 'feed'
  });

  const changeSelect = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChange = (e) => {
    const newContent = e.target.value;

    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (newContent.length === 250) {
      error("Max number of characters is 250.", 5000)

    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (username) {
        const trimmedContent = formData.content.trim();
        const numNewLines = trimmedContent.split('\n').length - 1;
        
        if (trimmedContent.length < 5) {
          return error("Post is not long enough.", 5000);
        } else if (trimmedContent.length > 280 || numNewLines > 9) {
          return error("Post is too long.", 5000);
        }
        
        const profanityCheckResponse = await axios.get(`https://www.purgomalum.com/service/containsprofanity?text=${formData.title}+${formData.content}`);
        const profanityDetected = profanityCheckResponse.data;

        if (profanityDetected) {
          error('Profanity detected in the title or content.', 3000);
          return;
        }

        const postData = {
          ...formData
        };

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/post`, postData, { withCredentials: true });
        if (response.data.success === false) {
          error(response.data.message, 3000)

        } else {
          success(response.data.message, 3000)
          setFormData({
            author: username,
            content: '',
            type: 'feed'
          });
        }
      } else {
        error("Please log in to post.", 5000)
      }


    } catch (error) {
      console.error('Error creating post:', error);
    }
  };


  return (
    <div className='Share-wrapper'>
      <div className='Share'>
        <div className='share-info share-title'>
          <h1>Post</h1>
        </div>
        <div className='share-box'>
          <form className='share-form' onSubmit={handleSubmit}>
            <div className='share-top'>
              <label className='share-label'>
                {username ? (`Sharing as ${username}`) : (`Log in or create an account to post.`)}
              </label>
              <a className='share-close' onClick={handleCloseShare}> &#10006; </a>
            </div>
            <textarea maxLength={250} placeholder='Write about something...' className="share-textarea" name="content" value={formData.content} onChange={handleChange} />
            <div className='share-actions' >
              <select className="share-btn share-select" name="type" value={formData.type} onChange={changeSelect}>
                <option value="feed">Everyone</option>
                <option value="anon">Anonymous</option>
              </select>
              <button className="share-btn" type="submit">Share</button>
            </div>
          </form>
        </div>
        <div className='share-info'>
          <text>
            When you share with <strong>Everyone</strong>, your post will be visible in the <strong>feed</strong> for everyone to see.
          </text>
          <text>
            Sharing as <strong>Anonymous</strong> means your post will be submitted to our site. It will then undergo processing before appearing in the <strong> news </strong>.
          </text>
        </div>
        <div className='share-info'>
          <text>
            Alternatively, you can email your information to <a href={`mailto:share@campustalk.io`}><strong>share@campustalk.io</strong></a>.
          </text>
        </div>
      </div>
      <Toaster reverseOrder={true} />

    </div>
  );
}

export default Share;
